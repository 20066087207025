const defaultAlertTimeout = 5000;

/* eslint-disable no-underscore-dangle */
const appConfig = window && window.appConfig ? window.appConfig : {};
/* eslint-enable no-underscore-dangle */

function getEnvironmentName() {
  const url = window.location.href.toLowerCase();

  if (url.includes("staging")) {
    return ["STG", "STAGING"];
  }
  if (url.includes("localhost") || url.includes("127.0.0.1")) {
    return ["LCL", "LOCALHOST"];
  }
  return ["PRD", "PRODUCTION"];
}

function getBaseURL() {
  const baseURL = appConfig.baseURL || "/tlc-waterfalls";
  return baseURL;
}

const admobCredentials = {
  client_id: "",
  scope: "https://www.googleapis.com/auth/admob.readonly",
};

const isExternalDashboard = appConfig.isExternalDashboard || false;
const supportEmail = appConfig.supportEmail || "arc@tripledotstudios.com";

export {
  getEnvironmentName,
  defaultAlertTimeout,
  admobCredentials,
  getBaseURL,
  isExternalDashboard,
  supportEmail,
};
